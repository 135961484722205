import { makeCompoundId } from "@/helpers";
import { sendRequest } from "@/SharedChart/main";
import store from "@/store";
import { ChatQuestion, Response } from "@/ts/interfaces/Question";
import { addDays } from "date-fns";

export default class ResponseGenerator {
    questionsCopy: ChatQuestion[] = [];
    questions: ChatQuestion[] = [];
    firstQuestionIndex: number = 0;
    lastSet: number = 0;

    responseCount: number = 0;
    records: (Response[])[] = [];
    date: Date = new Date();

    constructor(count: number, questions: ChatQuestion[]){
        this.questionsCopy = questions;
        this.responseCount = count;
        this.firstQuestionIndex = questions.findIndex(q => q.type !== 'legend');
        this.start();
    }

    start(){
        this.questions = JSON.parse(JSON.stringify(this.questionsCopy));
        this.lastSet = this.firstQuestionIndex;
        this.respond();
    }

    respond(){
        const question = this.questions[this.lastSet];
        switch(question.type.slice(0, 5)){
            case 'check': {
                const values = [];
                const selected = question.selected as { [key: string]: boolean };
                for(const s in selected){
                    if(!['na', 'other'].includes(s)){
                        const isSelected = selected[s] = Boolean(Math.round(Math.random()));
                        if(isSelected)
                            values.push(s);
                    }
                }
                question.val = `[${values.join(',')}]`;
                break;
            }
            case 'radio': {
                const index = Math.round(Math.random() * (Object.keys(question.options!).length - 1));
                question.val = question.selected = index;
            }
        }

        this.proceed();
    }

    meetsCondition({ condition, tree_path }: ChatQuestion = {} as ChatQuestion){
        if(typeof tree_path === 'undefined')
            return true;

        const parent = this.questions.find(q => tree_path === makeCompoundId(q));

        if(parent?.show && (typeof parent.val !== 'string' || parent.val.length))
            for(const c of condition)
                if(this.checkCondition(parent, c))
                    return true;
                
        return false;
    }

    checkCondition({ val, selected }: ChatQuestion, c: string | number){
        if(typeof c === 'number' || Number.isInteger(c))
            return typeof selected === 'number' ? val === c : selected?.[c] || false;

        const comparison = c.match(/([><]{0,2}=?)/g)!.join('');
        const [cMax, cVal] = c.split(comparison);
        switch(comparison){
            case '=':  return val === cVal;
            case '>':  return val > cVal;
            case '<':  return val < cVal;
            case '>=': return val >= cVal;
            case '<=': return val <= cVal;
            case '>>': return cMax > val && val > cVal;
        }
    }

    proceed(){
        const { questions, lastSet } = this;
        Object.assign(questions[lastSet], { show: 1, mark: 1 });
        for(let i = lastSet + 1; i < questions.length; i++){
            const question = questions[i];
            if(typeof question.show === 'undefined' || question.mark || !this.meetsCondition(question))
                continue;

            question.show = true;
            this.lastSet = i;
            return this.respond();
        }

        this.finalize();
    }

    finalize(){
        const record: Response[] = [];
        for(const q of this.questions) if(q.show)
            record.push({
                question_id: q.id,
                value: q.val,
                tree_path: q.tree_path ?? null,
                created_at: this.date.toJSON()
            });
        this.date = addDays(this.date, 1);
        this.records.push(record);

        if(this.records.length < this.responseCount)
            return this.start();
       
        const { questionnaireMetadata } = store.state.chat!;
        const { id, time } = questionnaireMetadata!;
        sendRequest(`/respond/${id}/${time}`, 'put', this.records.flat());
    }
}